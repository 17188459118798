// ParticipationForm.js

import React, { useState } from "react";
import ParticipationFormLayout from "./ParticipationFormLayout";
import { jwtDecode } from "jwt-decode"; // Ensure correct import
import "./ParticipationForm.css";
import { useNavigate } from "react-router-dom";

const ParticipationForm = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    age: "",
    dateOfBirth: "",
    email: "",
    phone: "",
    photo_permission: false, // Correct field name
    waiverConsent: false,
    parentGuardianName: "",
    emergencyContact1Name: "",
    emergencyContact1Phone: "",
    emergencyContact2Name: "",
    emergencyContact2Phone: "",
    disability: "",
    allergies: "",
    behaviors: "",
    conserved: false,
    preferredHospital: "",
    primaryPhysician: "",
    serviceCoordinator: "",
    serviceCoordinatorPhone: "",
    address: "", // Added 'address' field
    photo_consent: false // Renamed from photoMarketingPermission
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const formatPhoneNumber = (phoneNumber) => {
    let cleaned = ("" + phoneNumber).replace(/\D/g, "");
    if (cleaned.startsWith("1")) {
      cleaned = cleaned.substring(1);
    }
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    return match ? `${match[1]}-${match[2]}-${match[3]}` : phoneNumber;
  };

  const handleChange = (event) => {
    const { name, type, value, checked } = event.target;
    let formattedValue = value;

    if (
      name === "phone" ||
      name === "emergencyContact1Phone" ||
      name === "emergencyContact2Phone"
    ) {
      formattedValue = formatPhoneNumber(value) || value;
    } else if (type === "checkbox") {
      formattedValue = checked;
    } else if (name === "conserved") {
      formattedValue = value === "true";
    }

    setFormData((prevData) => ({ ...prevData, [name]: formattedValue }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    console.log("Form submission initiated.");

    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found, redirecting to login");
      alert("Authentication required. Redirecting to login.");
      navigate("/login");
      setIsSubmitting(false);
      return;
    }

    let decoded;
    try {
      decoded = jwtDecode(token);
      console.log("Token decoded successfully:", decoded);
    } catch (error) {
      console.error("Failed to decode token:", error);
      alert("Invalid authentication token. Please log in again.");
      navigate("/login");
      setIsSubmitting(false);
      return;
    }

    const user_id = decoded.user_id;
    if (!user_id) {
      console.error("User ID not found in token.");
      alert("Authentication error. Please log in again.");
      navigate("/login");
      setIsSubmitting(false);
      return;
    }

    const submissionData = { ...formData, user_id };
    console.log("Submission data:", submissionData);

    const saveParticipationFormEndpoint =
      "https://www.ptemployment.org/api/auth/saveParticipationForm";

    try {
      const response = await fetch(saveParticipationFormEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(submissionData)
      });

      if (response.ok) {
        console.log("Form submitted successfully.");
        alert("Form submitted successfully.");
        navigate("/userProfile");
      } else {
        const errorData = await response.json();
        console.error("Failed to submit the form:", errorData);
        alert(
          `Failed to submit the form: ${
            errorData.message || "Please try again."
          }`
        );
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("Error submitting form, please try again.");
    } finally {
      setIsSubmitting(false);
      console.log("Form submission process ended.");
    }
  };

  return (
    <div className="form-container">
      <ParticipationFormLayout
        formData={formData}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        isSubmitting={isSubmitting}
      />
    </div>
  );
};

export default ParticipationForm;
