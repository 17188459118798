import React, { useState, useEffect, useContext, useRef } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment-timezone";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./CustomCalendar.css";
import EventDetailsPopup from "../EventDetailsPopup/EventDetailsPopup";
import { ClassesContext } from "../ClassesContext/ClassesContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";

// Initialize the localizer with moment
const localizer = momentLocalizer(moment);

const MonthlySchedule = () => {
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [showEventDetails, setShowEventDetails] = useState(false);
  const [events, setEvents] = useState([]);
  const navigate = useNavigate();
  const calendarRef = useRef(null);

  const { classes } = useContext(ClassesContext);

  // Function to fetch events from the backend
  const fetchEvents = async () => {
    try {
      const eventsEndpoint =
        "https://www.ptemployment.org/api/classes/class-schedules";
      const response = await axios.get(eventsEndpoint, {
        headers: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0"
        }
      });
      console.log("Fetched events:", response.data);
      setEvents(response.data);
    } catch (error) {
      console.error("Error fetching events:", error);
      alert("Failed to fetch events. Please try again later.");
    }
  };

  // Fetch events on component mount and when 'classes' change
  useEffect(() => {
    if (classes && classes.length > 0) {
      setEvents(classes); // Set events from context if available
    } else {
      fetchEvents(); // Fallback to fetching directly if context is empty
    }
  }, [classes]);

  // Function to parse event times as local times
  const parseEventTimeAsLocal = (timeString) => {
    const localTime = moment.utc(timeString).local();
    return localTime.toDate();
  };

  // Format events for calendar
  const formattedClasses = Array.isArray(events)
    ? events.map((event) => ({
        ...event,
        start: parseEventTimeAsLocal(event.start_time),
        end: parseEventTimeAsLocal(event.end_time),
        title: event.title,
        originalStartTime: event.start_time,
        originalEndTime: event.end_time
      }))
    : [];

  // Function to style events based on their color
  const eventStyleGetter = (event) => {
    let backgroundColor = event.event_color || "#3174ad";
    return {
      style: {
        backgroundColor,
        borderRadius: "5px",
        opacity: 0.8,
        color: "white",
        border: "0px",
        display: "block",
        overflow: "visible"
      }
    };
  };

  // Handle event selection
  const handleSelectEvent = (event) => {
    console.log("Selected event ID:", event.id);
    setSelectedEvent(event);
    setShowEventDetails(true);
  };

  // Handle closing the event details popup
  const handleCloseDetails = () => {
    setShowEventDetails(false);
    setSelectedEvent(null);
  };

  // Function to scroll to today's date in the calendar
  const scrollToToday = () => {
    const calendarElement = calendarRef.current;
    if (calendarElement) {
      const todayElement = calendarElement.querySelector(".rbc-today");
      if (todayElement) {
        todayElement.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  };

  // Function to format time from original time strings
  const formatTimeFromString = (timeString) => {
    if (!timeString) return "";
    const localTime = moment.utc(timeString).local();
    return localTime.format("h:mm A");
  };

  // CustomEvent component to display event details with proper time formatting
  const CustomEvent = ({ event }) => {
    return (
      <div
        style={{
          whiteSpace: "normal",
          overflow: "hidden",
          height: "auto",
          padding: "2px 5px"
        }}
      >
        <strong>{event.title}</strong>
        <div>
          {formatTimeFromString(event.originalStartTime)} -{" "}
          {formatTimeFromString(event.originalEndTime)}
        </div>
      </div>
    );
  };

  return (
    <div className="monthly-schedule-container" style={{ paddingTop: "80px" }}>
      <h1>Monthly Schedule</h1>
      <button className="btn btn-success my-3" onClick={scrollToToday}>
        Jump to Today
      </button>
      <div style={{ height: "800px", overflowY: "auto" }} ref={calendarRef}>
        <Calendar
          localizer={localizer}
          events={formattedClasses}
          startAccessor="start"
          endAccessor="end"
          onSelectEvent={handleSelectEvent}
          eventPropGetter={eventStyleGetter}
          style={{ minHeight: "3300px" }}
          views={["month"]}
          components={{
            event: CustomEvent
          }}
        />
      </div>
      {showEventDetails && selectedEvent && (
        <EventDetailsPopup
          event={selectedEvent}
          onClose={handleCloseDetails}
          refreshEvents={fetchEvents} // Pass the refresh function
          displayOnly={false} // Set to false to allow enrollment actions
        />
      )}
    </div>
  );
};

export default MonthlySchedule;
