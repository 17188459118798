import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import "./Login.css";
import { jwtDecode } from "jwt-decode";
import { GoogleLogin } from "@react-oauth/google";
import { useAuth } from "../context/AuthContext";

const clientId =
  "711154044658-25o8imctgqmdri9drjqktehqairc2sa6.apps.googleusercontent.com";

const LogIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginStatus, setLoginStatus] = useState("");
  const navigate = useNavigate();
  const { login } = useAuth(); // Destructure login from AuthContext

  const handleSuccess = async (credentialResponse) => {
    try {
      // Decoding the JWT to get user info
      const user = jwtDecode(credentialResponse.credential);
      const oauthLoginEndpoint =
        "https://www.ptemployment.org/api/auth/oauth-login";
      const response = await fetch(oauthLoginEndpoint, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email: user.email, oauth_provider: "Google" }) // Assuming email is in the JWT
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error(
          data.message || "Failed to authenticate user in backend"
        );
      }
      // Use AuthContext's login function
      login({ name: user.email }, data.token);
      navigate("/userProfile");
    } catch (error) {
      console.error("Error sending user to backend:", error);
      setLoginStatus(`Login failed. Error: ${error.message}`);
    }
  };

  const handleError = (error) => {
    console.error("Google Login Failed", error);
    setLoginStatus("Google login failed. Please try again.");
  };

  // Inside handleLogin function in Login.js

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch(
        "https://www.ptemployment.org/api/auth/login",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ email, password })
        }
      );
      const data = await response.json();
      if (!response.ok)
        throw new Error(data.message || "Incorrect email or password");

      // Decode token to get user info
      const decodedToken = jwtDecode(data.token);

      // **Store role in localStorage**
      localStorage.setItem("role", decodedToken.role || "User");

      login(
        { name: decodedToken.username, id: decodedToken.user_id },
        data.token
      );
      navigate("/userProfile");
    } catch (error) {
      console.error("Login failed", error);
      setLoginStatus("Login failed. Please try again.");
    }
  };

  return (
    <div className="login-container">
      <h1>Log In</h1>
      <div className="social-login mb-2">
        <GoogleLogin
          clientId={clientId}
          onSuccess={handleSuccess}
          onError={handleError}
          render={(renderProps) => (
            <button
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}
              className="btn btn-google"
            >
              Login with Google
            </button>
          )}
        />
      </div>
      <div className="separator mb-3">or</div>
      <div className="email-login">
        <form
          onSubmit={handleLogin}
          className="d-flex flex-column align-items-center"
        >
          <input
            type="email"
            className="form-control mb-2"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="password"
            className="form-control mb-3"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            autoComplete="current-password"
          />
          <button type="submit" className="btn btn-success w-100">
            Log in
          </button>
        </form>
        {loginStatus && <p className="mt-2 text-danger">{loginStatus}</p>}
      </div>
      <div className="signup-link mt-3">
        Not a user?{" "}
        <Link to="/register" className="text-primary">
          Sign up here
        </Link>
      </div>
    </div>
  );
};

export default LogIn;
