// StripeCheckoutPage.js

import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  CardElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import "./StripeCheckoutPage.css";
import { useCart } from "../CartContext/CartContext";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../context/AuthContext"; // Import useAuth
import axios from "axios";
import { jwtDecode } from "jwt-decode";

const stripePromise = loadStripe("pk_test_OMc2XdFbsaVBFTiZwAj2veuQ");

const StripeCheckoutPage = () => {
  const { cartItems } = useCart();
  const navigate = useNavigate();
  const location = useLocation();
  const { getToken, userRole } = useAuth(); // Access userRole
  const [token, setToken] = useState(null);
  const [userId, setUserId] = useState(null);
  const [isSDP, setIsSDP] = useState(false); // State to determine if user is SDP
  const eventIds = cartItems.map((item) => item.id);

  const totalAmount = cartItems.reduce((acc, item) => acc + item.price, 0);

  const onSuccess = () => {
    console.log("Payment and registration successful");
    setTimeout(() => {
      navigate("/userProfile");
    }, 3000);
  };

  // Determine if the user can checkout without payment
  useEffect(() => {
    const retrievedToken = getToken();
    setToken(retrievedToken);

    if (retrievedToken) {
      const decodedToken = jwtDecode(retrievedToken);
      setUserId(decodedToken.user_id);
      setIsSDP(decodedToken.role === "Self Determination Program (SDP)");
    }
  }, [getToken]);

  return (
    <div className="container mt-5">
      <Elements stripe={stripePromise}>
        <CheckoutForm
          cartItems={cartItems}
          totalAmount={totalAmount}
          userId={userId}
          eventIds={eventIds}
          onSuccess={onSuccess}
          navigate={navigate}
          isSDP={isSDP} // Pass isSDP as a prop
          userRole={userRole} // Pass userRole as a prop
          token={token} // Pass token as a prop
        />
      </Elements>
    </div>
  );
};

const CheckoutForm = ({
  cartItems,
  totalAmount,
  userId,
  eventIds,
  onSuccess,
  navigate,
  isSDP,
  userRole,
  token
}) => {
  const { clearCart } = useCart();
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState("");

  // Function to handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    setMessage("");

    // If the user can checkout without payment, skip the payment process
    if (isSDP || userRole === "staff" || userRole === "administrator") {
      try {
        const baseURL =
          process.env.REACT_APP_API_URL || "http://localhost:3000";

        // Register each event
        const responses = await Promise.all(
          cartItems.map((item) => {
            return axios.post(
              `${baseURL}/api/event-registrations`,
              {
                user_id: userId,
                event_id: item.id
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`
                }
              }
            );
          })
        );

        const allSucceeded = responses.every(
          (response) => response.status === 200
        );
        if (allSucceeded) {
          // If user is SDP, create an invoice
          if (isSDP) {
            const invoiceResponse = await axios.post(
              `${baseURL}/backend/quickbooks/create-invoice`,
              {
                amount: totalAmount,
                itemId: "12345", // Replace with your actual item ID
                itemName: "Class Registration",
                customerId: userId,
                user_id: userId
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`
                }
              }
            );

            if (invoiceResponse.status === 200) {
              console.log(
                "Invoice created successfully:",
                invoiceResponse.data
              );
            } else {
              throw new Error("Invoice creation failed.");
            }
          }

          clearCart();
          setMessage("Registration successful!");
          onSuccess();
        } else {
          throw new Error("Some registrations failed.");
        }
      } catch (error) {
        console.error("Registration error:", error);
        setMessage("Registration failed. Please try again.");
      }
      return;
    }

    // Proceed with Stripe payment if user cannot checkout without payment
    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardElement);
    const cardholderName = document.getElementById("cardholder-name").value;
    const address = document.getElementById("address").value;
    const city = document.getElementById("city").value;

    // Create a payment method
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: {
        name: cardholderName,
        address: {
          line1: address,
          city: city
        }
      }
    });

    if (error) {
      console.error("Payment error:", error);
      setMessage("Payment failed. Please try again.");
      return;
    }

    console.log("Payment successful:", paymentMethod);

    try {
      const baseURL = process.env.REACT_APP_API_URL || "http://localhost:3000";

      // Register each event
      const registrationPromises = cartItems.map((item) => {
        return axios.post(
          `${baseURL}/api/event-registrations`,
          {
            user_id: userId,
            event_id: item.id
          },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );
      });

      const responses = await Promise.all(registrationPromises);
      const allSucceeded = responses.every(
        (response) => response.status === 200
      );

      if (allSucceeded) {
        clearCart();
        setMessage("Payment and all registrations successful!");
        if (typeof onSuccess === "function") {
          onSuccess();
        }
      } else {
        setMessage(
          "Payment succeeded but one or more registrations failed. Please contact support."
        );
      }
    } catch (err) {
      console.error("Registration error:", err);
      setMessage("An error occurred during registration.");
    }
  };

  return (
    <>
      <div className="d-flex justify-content-center mt-1 mb-1">
        <button onClick={() => navigate("/userProfile")}>
          Back to Profile
        </button>
        <button className="btn btn-primary" onClick={() => navigate("/cart")}>
          Back to Cart
        </button>
      </div>
      <form onSubmit={handleSubmit} className="checkout-form">
        <h2 className="text-center mb-4">Your Cart</h2>
        <ul className="list-group mb-4">
          {cartItems.map((item, index) => (
            <li
              key={index}
              className="list-group-item d-flex justify-content-between align-items-center"
            >
              {item.title}
              <span>${Number(item.price).toFixed(2)}</span>
            </li>
          ))}
        </ul>
        <h3 className="text-center mb-4">
          Total: ${Number(totalAmount).toFixed(2)}
        </h3>

        {/* Only show payment fields if the user cannot checkout without payment */}
        {!(isSDP || userRole === "staff" || userRole === "administrator") && (
          <>
            <h2 className="text-center mb-4">Complete Your Payment</h2>
            <div className="mb-3">
              <label htmlFor="cardholder-name" className="form-label">
                Cardholder's Name
              </label>
              <input
                type="text"
                id="cardholder-name"
                className="form-control"
                placeholder="Name on Card"
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="address" className="form-label">
                Address (optional)
              </label>
              <input
                type="text"
                id="address"
                className="form-control"
                placeholder="1234 Main St"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="city" className="form-label">
                City (optional)
              </label>
              <input
                type="text"
                id="city"
                className="form-control"
                placeholder="City"
              />
            </div>
            <div className="mb-3">
              <label htmlFor="card-element" className="form-label">
                Card Details
              </label>
              <CardElement id="card-element" className="form-control" />
            </div>
            <button
              type="submit"
              className="btn btn-primary btn-block"
              disabled={!stripe}
            >
              Pay
            </button>
            {message && <div className="alert alert-info mt-3">{message}</div>}
          </>
        )}
        {/* If the user can checkout without payment, provide a button to do so */}
        {(isSDP || userRole === "staff" || userRole === "administrator") && (
          <button
            type="submit"
            className="btn btn-primary btn-block mt-3"
            disabled={!stripe}
          >
            Confirm Registration Without Payment
          </button>
        )}
      </form>
      <div className="d-flex justify-content-center mt-3 mb-4">
        <button onClick={() => navigate("/userProfile")}>
          Back to Profile
        </button>
        <button className="btn btn-primary" onClick={() => navigate("/cart")}>
          Back to Cart
        </button>
      </div>
    </>
  );
};

export default StripeCheckoutPage;
