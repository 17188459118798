// src/components/ClassScheduler/ClassScheduler.js

import React, { useState, useEffect } from "react";
import axios from "axios";
import EventDetailsPopup from "../EventDetailsPopup/EventDetailsPopup";
import "./ClassScheduler.css";
import RecurringDatePicker from "../RecurringDatePicker/RecurringDatePicker";
import moment from "moment-timezone";
import { useNavigate } from "react-router-dom";
import { Calendar, momentLocalizer } from "react-big-calendar";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import "react-big-calendar/lib/css/react-big-calendar.css";

// Function to round time to the next 15-minute interval
const roundToNext15Minutes = (time) => {
  const minutes = time.minute();
  const roundedMinutes = Math.ceil(minutes / 15) * 15;
  if (roundedMinutes === 60) {
    return time.clone().startOf("hour").add(1, "hour");
  } else {
    return time.clone().minute(roundedMinutes).second(0);
  }
};

const localizer = momentLocalizer(moment);

const ClassScheduler = () => {
  // Retrieve the user's role from localStorage
  const userRole = localStorage.getItem("role");
  console.log("User Role in ClassScheduler:", userRole); // Debugging log

  const [events, setEvents] = useState([]);

  // Initialize start_time and end_time using the rounding function
  const initialStartTime = roundToNext15Minutes(moment());
  const initialEndTime = initialStartTime.clone().add(1, "hour");

  const [eventData, setEventData] = useState({
    title: "",
    start_time: initialStartTime,
    end_time: initialEndTime,
    description: "",
    price: "",
    hours: "",
    description_url: "",
    event_color: "",
    is_all_day: false,
    image_url: ""
  });

  const [selectedClassId, setSelectedClassId] = useState("");
  const [classes, setClasses] = useState([]);
  const [isAutofilled, setIsAutofilled] = useState(false);
  const [recurringDates, setRecurringDates] = useState([]);
  const [imageFile, setImageFile] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [showEventDetails, setShowEventDetails] = useState(false);
  const [showRecurringForm, setShowRecurringForm] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date()); // Added state
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const eventsEndpoint =
          "https://www.ptemployment.org/api/classes/class-schedules";

        const token = localStorage.getItem("token");

        const response = await axios.get(eventsEndpoint, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        console.log("Fetched events:", response.data);
        setEvents(response.data);
        setClasses(response.data); // Update the classes context
      } catch (error) {
        console.error("Error fetching events:", error);
        alert("Failed to fetch events. Please try again later.");
      }
    };
    fetchEvents();
  }, []);

  const parseEventTimeAsLocal = (timeString) => {
    // Parse the time string as UTC and convert to local time
    const localTime = moment.utc(timeString).local();
    return localTime.toDate();
  };

  const eventsForCalendar = events.map((event) => {
    const start = parseEventTimeAsLocal(event.start_time);
    const end = parseEventTimeAsLocal(event.end_time);

    console.log(`Event ID ${event.id}: start ${start}, end ${end}`);
    return {
      ...event,
      start,
      end,
      title: event.title,
      originalStartTime: event.start_time,
      originalEndTime: event.end_time
    };
  });

  console.log("Events for calendar after mapping:", eventsForCalendar);

  const handleDateChange = (field, date) => {
    let newEventData = { ...eventData };
    const roundedTime = roundToNext15Minutes(moment(date));
    newEventData[field] = roundedTime;
    if (field === "start_time") {
      const endTime = roundedTime.clone().add(1, "hour");
      newEventData.end_time = endTime;
    }
    setEventData(newEventData);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEventData({
      ...eventData,
      [name]: value
    });
  };

  const handleClassSelectionChange = (e) => {
    setSelectedClassId(e.target.value);
  };

  const handleAutofill = () => {
    const selectedEvent = classes.find(
      (event) => event.title === selectedClassId
    );
    if (selectedEvent) {
      setEventData({
        ...eventData,
        title: selectedEvent.title,
        description: selectedEvent.description,
        price: selectedEvent.price,
        hours: selectedEvent.hours,
        image_url: selectedEvent.image_url
      });
      setIsAutofilled(true);
    }
  };

  const handleDateSelect = (date) => {
    const formattedDate = moment(date).format("YYYY-MM-DD");

    setRecurringDates((prevDates) =>
      prevDates.includes(formattedDate)
        ? prevDates.filter((d) => d !== formattedDate)
        : [...prevDates, formattedDate]
    );
  };

  const pastelColors = [
    // Reds (10)
    { name: "Pastel Blush", color: "#ffc3a0" },
    { name: "Pastel Salmon", color: "#ffb0ac" },
    { name: "Pastel Pink", color: "#ffb6c1" },

    { name: "Pastel Rose", color: "#ff6f61" },
    { name: "Pastel Red", color: "#ff6961" },
    { name: "Pastel Coral", color: "#ff6b81" },

    { name: "Pastel Ruby", color: "#e75480" },
    { name: "Crimson", color: "#dc143c" },
    { name: "Regular Red", color: "#ff0000" },

    { name: "Dark Red", color: "#8b0000" },

    // Oranges (9)
    { name: "Pastel Peach", color: "#ffcc99" },
    { name: "Pastel Tangerine", color: "#ffcc80" },
    { name: "Pastel Orange", color: "#ffbb91" },
    { name: "Light Orange", color: "#ffd580" },
    { name: "Amber", color: "#ffbf00" },
    { name: "Regular Orange", color: "#ffa500" },
    { name: "Dark Orange", color: "#ff8c00" },
    { name: "Vivid Orange", color: "#ff4500" },
    { name: "Burnt Orange", color: "#cc5500" },

    // Yellows (8)
    { name: "Light Yellow", color: "#ffffe0" },
    { name: "Pastel Lemon", color: "#fff44f" },
    { name: "Pastel Yellow", color: "#fdfd96" },
    { name: "Regular Yellow", color: "#ffff00" },
    { name: "Mustard Yellow", color: "#ffdb58" },
    { name: "Pastel Gold", color: "#ffd700" },
    { name: "Dark Yellow", color: "#ffcc00" },
    { name: "Goldenrod", color: "#daa520" },

    // Greens (9)
    { name: "Pastel Moss Green", color: "#addfad" },
    { name: "Pastel Lime", color: "#bfff00" },
    { name: "Pastel Mint", color: "#98ff98" },
    { name: "Pastel Green", color: "#77dd77" },
    { name: "Regular Green", color: "#00ff00" },
    { name: "Lime Green", color: "#32cd32" },
    { name: "Olive Green", color: "#808000" },
    { name: "Forest Green", color: "#228b22" },
    { name: "Dark Green", color: "#006400" },

    // Blues (including Cyan) (10)
    { name: "Pastel Azure", color: "#f0ffff" },
    { name: "Pastel Periwinkle", color: "#c3cde6" },
    { name: "Pastel Blue", color: "#aec6cf" },
    { name: "Pastel Aqua", color: "#7fffd4" },
    { name: "Pastel Teal", color: "#99ffcc" },
    { name: "Pastel Turquoise", color: "#40e0d0" },
    { name: "Pastel Sky", color: "#87ceeb" },
    { name: "Cyan", color: "#00ffff" },
    { name: "Regular Blue", color: "#0000ff" },
    { name: "Dark Blue", color: "#00008b" },

    // Purples (including Violet and Magenta) (10)
    { name: "Pastel Lavender Blush", color: "#fff0f5" },
    { name: "Lavender", color: "#e6e6fa" },
    { name: "Pastel Lavender", color: "#e6e6fa" },
    { name: "Pastel Purple", color: "#b39eb5" },
    { name: "Pastel Lilac", color: "#c8a2c8" },
    { name: "Pastel Plum", color: "#dda0dd" },
    { name: "Violet", color: "#ee82ee" },
    { name: "Pastel Orchid", color: "#db70db" },
    { name: "Magenta", color: "#ff00ff" },
    { name: "Regular Purple", color: "#800080" }
  ];

  const handleColorSelect = (color) => {
    setEventData({ ...eventData, event_color: color });
  };

  // Define the ColorPicker component
  const ColorPicker = ({ selectedColor, onSelectColor }) => {
    return (
      <div className="color-picker">
        {pastelColors.map((colorOption) => (
          <div
            key={colorOption.color}
            className={`color-option ${
              selectedColor === colorOption.color ? "selected" : ""
            }`}
            style={{ backgroundColor: colorOption.color }}
            onClick={() => onSelectColor(colorOption.color)}
            title={colorOption.name}
          />
        ))}
      </div>
    );
  };

  const handleSelectEvent = (event) => {
    setSelectedEvent(event);
    setShowEventDetails(true);
  };

  const handleCloseDetails = () => {
    setShowEventDetails(false);
    setSelectedEvent(null);
  };

  const handleEditEvent = async (updatedEvent) => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found. Please log in.");
      alert("No token found. Please log in.");
      return;
    }

    try {
      const updateEndpoint =
        "https://www.ptemployment.org/api/classes/class-schedules/" +
        updatedEvent.id;

      const response = await axios.put(updateEndpoint, updatedEvent, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      });
      setEvents(
        events.map((event) =>
          event.id === updatedEvent.id ? response.data : event
        )
      );
      alert("Event updated successfully.");
    } catch (error) {
      console.error("Error updating event:", error);
      alert("Failed to update the event. Please try again.");
    }
  };

  const handleDeleteEvent = async (eventId) => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found. Please log in.");
      alert("No token found. Please log in.");
      return;
    }

    try {
      const response = await axios.delete(
        `https://www.ptemployment.org/api/classes/class-schedules/${eventId}`,
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      if (response.status === 200 || response.status === 204) {
        setEvents((prevEvents) =>
          prevEvents.filter((event) => event.id !== eventId)
        );
        alert("Event deleted successfully.");
      } else {
        console.error(`Unexpected response status: ${response.status}`);
        alert("Failed to delete the event. Please try again.");
      }
    } catch (error) {
      console.error("Error deleting event:", error);
      alert("Failed to delete the event. Please try again.");
    }
  };

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setImageFile(e.target.files[0]);
    }
  };

  const handleImageUpload = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found. Please log in.");
      alert("No token found. Please log in.");
      return null;
    }

    if (imageFile) {
      const formData = new FormData();
      formData.append("file", imageFile);

      try {
        const uploadEndpoint = "https://www.ptemployment.org/upload";
        const uploadResponse = await axios.post(uploadEndpoint, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`
          },
          responseType: "text"
        });

        if (uploadResponse.status === 200) {
          console.log("Image uploaded successfully:", uploadResponse.data);
          const imageUrl = uploadResponse.data.split(" to ")[1];
          setEventData((prevEventData) => ({
            ...prevEventData,
            image_url: imageUrl
          }));
          console.log("Image URL set to:", imageUrl);
          return imageUrl;
        }
      } catch (uploadError) {
        console.error("Error uploading image:", uploadError);
        alert("Failed to upload image. Please try again.");
      }
    }
    return null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation for price
    if (!eventData.price || parseFloat(eventData.price) === 0) {
      alert("Price cannot be zero or left blank. Please enter a valid price.");
      return;
    }

    // Validation for hours
    if (!eventData.hours || parseFloat(eventData.hours) === 0) {
      alert(
        "Hours cannot be zero or left blank. Please enter a valid number of hours."
      );
      return;
    }

    // Warning for missing image
    if (!imageFile && !eventData.image_url) {
      const proceedWithoutImage = window.confirm(
        "No image selected. Do you want to proceed without an image?"
      );
      if (!proceedWithoutImage) {
        return;
      }
    }

    const imageUrl = await handleImageUpload();

    // Convert local times to UTC
    const start_time = moment(eventData.start_time)
      .utc()
      .format("YYYY-MM-DDTHH:mm:ss[Z]");
    const end_time = moment(eventData.end_time)
      .utc()
      .format("YYYY-MM-DDTHH:mm:ss[Z]");

    const eventDataToSubmit = {
      ...eventData,
      start_time,
      end_time,
      image_url: imageUrl || eventData.image_url,
      price: eventData.price || null,
      hours: eventData.hours || null
    };

    console.log("Submitting eventData:", eventDataToSubmit);

    // Pass the original start_time to set the calendar's view
    await submitEvent(eventDataToSubmit, eventData.start_time);
  };

  const submitEvent = async (eventData, eventDate = null) => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found. Please log in.");
      alert("No token found. Please log in.");
      return;
    }

    try {
      const postEndpoint =
        "https://www.ptemployment.org/api/classes/class-schedules";
      const response = await axios.post(postEndpoint, eventData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      });

      if (response.status === 201) {
        console.log("Event successfully added:", response.data);
        setEvents((prevEvents) => [...prevEvents, response.data]);

        // Update the currentDate to the event's month
        if (eventDate) {
          setCurrentDate(moment(eventDate).toDate());
        }

        // Reset form after successful submission
        const resetStartTime = roundToNext15Minutes(moment());
        const resetEndTime = resetStartTime.clone().add(1, "hour");

        setEventData({
          title: "",
          start_time: resetStartTime,
          end_time: resetEndTime,
          description: "",
          price: "",
          hours: "",
          description_url: "",
          event_color: "",
          is_all_day: false,
          image_url: ""
        });
        setImageFile(null);
        setIsAutofilled(false);
        alert("Event added successfully!");
      }
    } catch (error) {
      console.error("Error adding event:", error);
      alert("Failed to add the event. Please try again.");
    }
  };

  const handleConfirmRecurringDates = async () => {
    console.log("Initial eventData:", eventData);
    console.log("Recurring dates before deduplication:", recurringDates);

    const uniqueDates = new Set(recurringDates);

    const initialDate = eventData.start_time.format("YYYY-MM-DD");
    uniqueDates.delete(initialDate);

    console.log("Initial date:", initialDate);
    console.log("Unique dates after deduplication:", uniqueDates);

    // Validation for price
    if (!eventData.price || parseFloat(eventData.price) === 0) {
      alert("Price cannot be zero or left blank. Please enter a valid price.");
      return;
    }

    // Validation for hours
    if (!eventData.hours || parseFloat(eventData.hours) === 0) {
      alert(
        "Hours cannot be zero or left blank. Please enter a valid number of hours."
      );
      return;
    }

    // Warning for missing image
    if (!imageFile && !eventData.image_url) {
      const proceedWithoutImage = window.confirm(
        "No image selected. Do you want to proceed without an image?"
      );
      if (!proceedWithoutImage) {
        return;
      }
    }

    const imageUrl = await handleImageUpload();

    // Convert local times to UTC
    const start_time = moment(eventData.start_time)
      .utc()
      .format("YYYY-MM-DDTHH:mm:ss[Z]");
    const end_time = moment(eventData.end_time)
      .utc()
      .format("YYYY-MM-DDTHH:mm:ss[Z]");

    const initialEvent = {
      ...eventData,
      start_time,
      end_time,
      image_url: imageUrl || eventData.image_url,
      price: eventData.price === "" ? null : eventData.price,
      hours: eventData.hours === "" ? null : eventData.hours
    };

    console.log("Submitting initial event:", initialEvent);

    // Pass the original start_time to set the calendar's view
    await submitEvent(initialEvent, eventData.start_time);

    for (const date of uniqueDates) {
      const startTime = moment(
        `${date}T${eventData.start_time.format("HH:mm:ss")}`
      );
      const endTime = startTime
        .clone()
        .add(eventData.end_time.diff(eventData.start_time), "milliseconds");

      // Convert to UTC
      const start_time = startTime.utc().format("YYYY-MM-DDTHH:mm:ss[Z]");
      const end_time = endTime.utc().format("YYYY-MM-DDTHH:mm:ss[Z]");

      const newEvent = {
        ...eventData,
        start_time,
        end_time,
        image_url: imageUrl || eventData.image_url,
        price: eventData.price === "" ? null : eventData.price,
        hours: eventData.hours === "" ? null : eventData.hours
      };

      console.log("Submitting recurring event:", newEvent);

      // Pass the startTime to set the calendar's view
      await submitEvent(newEvent, startTime);
    }

    setRecurringDates([]);
    setShowRecurringForm(false);
  };

  const uniqueClassTitles = [...new Set(classes.map((event) => event.title))];

  const formatTimeFromString = (timeString) => {
    if (!timeString) return "";

    // Parse as UTC and convert to local time
    const localTime = moment.utc(timeString).local();

    return localTime.format("h:mm A");
  };

  const CustomEvent = ({ event }) => {
    return (
      <div
        style={{
          whiteSpace: "normal",
          overflow: "hidden",
          height: "auto",
          padding: "2px 5px"
        }}
      >
        <strong>{event.title}</strong>
        <div>
          {formatTimeFromString(event.originalStartTime)} -{" "}
          {formatTimeFromString(event.originalEndTime)}
        </div>
      </div>
    );
  };

  const eventStyleGetter = (event) => {
    let backgroundColor = event.event_color || "#3174ad";
    return {
      style: {
        backgroundColor,
        borderRadius: "5px",
        opacity: 0.8,
        color: "white",
        border: "0px",
        display: "block",
        overflow: "visible"
      }
    };
  };

  const handleRecurringClick = () => {
    setShowRecurringForm((prevShowRecurringForm) => !prevShowRecurringForm);
  };

  return (
    <div className="class-scheduler">
      <div className="mb-3">
        <button
          className="btn btn-primary"
          onClick={() => navigate("/userProfile")}
        >
          Back to Profile
        </button>
      </div>
      <h1>Class Scheduler</h1>
      <div className="class-scheduler-form-container">
        <div className="class-selection-autofill-container">
          <select
            value={selectedClassId}
            onChange={handleClassSelectionChange}
            className="class-selection-dropdown"
          >
            <option value="">Select a Previous Class</option>
            {uniqueClassTitles.map((title, index) => (
              <option key={index} value={title}>
                {title}
              </option>
            ))}
          </select>

          <button className="autofill-button" onClick={handleAutofill}>
            Autofill From Selected Class
          </button>
        </div>

        <form onSubmit={handleSubmit} className="class-scheduler-form">
          <input
            className="form-input"
            type="text"
            name="title"
            value={eventData.title}
            onChange={handleInputChange}
            placeholder="Class Title"
            required
          />
          {!isAutofilled && (
            <>
              <label htmlFor="image-upload" className="form-label">
                Event Image:
              </label>
              <input
                type="file"
                id="image-upload"
                onChange={handleImageChange}
                accept="image/*"
              />
            </>
          )}
          <div className="disclaimer">
            <p>
              If you are planning to make this event recurring, the specific
              date you choose here does not impact the recurrence schedule, as
              you can select the dates directly from the calendar below.
              However, the start and end times you set here will apply to all
              occurrences, so please ensure they reflect your intended schedule.
            </p>
          </div>
          <div className="datetime-picker">
            <label className="form-label">Start Time:</label>
            <Datetime
              value={eventData.start_time}
              onChange={(date) => handleDateChange("start_time", date)}
              timeConstraints={{ minutes: { min: 0, max: 59, step: 15 } }}
              dateFormat="YYYY-MM-DD"
              timeFormat="hh:mm A"
              inputProps={{ className: "datetime-input" }}
            />
          </div>
          <div className="datetime-picker">
            <label className="form-label">End Time:</label>
            <Datetime
              value={eventData.end_time}
              onChange={(date) => handleDateChange("end_time", date)}
              timeConstraints={{ minutes: { min: 0, max: 59, step: 15 } }}
              dateFormat="YYYY-MM-DD"
              timeFormat="hh:mm A"
              inputProps={{ className: "datetime-input" }}
            />
          </div>
          <textarea
            className="form-textarea description-textarea"
            name="description"
            value={eventData.description}
            onChange={handleInputChange}
            placeholder="Description"
          />
          <input
            className="form-input"
            type="number"
            name="price"
            value={eventData.price}
            onChange={handleInputChange}
            placeholder="Price"
            required
          />
          <input
            className="form-input"
            type="number"
            name="hours"
            value={eventData.hours}
            onChange={handleInputChange}
            placeholder="Hours"
            required
          />
          <input
            type="hidden"
            name="image_url"
            value={eventData.image_url}
            onChange={handleInputChange}
          />
          <label className="form-label">Event Color:</label>
          <ColorPicker
            selectedColor={eventData.event_color}
            onSelectColor={handleColorSelect}
          />
          <button
            type="button"
            className="form-button"
            onClick={handleRecurringClick}
          >
            Make Recurring
          </button>
          {showRecurringForm && (
            <>
              <div className="recurring-section">
                <p className="disclaimer">
                  Click on the days of the month that you want this event to be
                  recurring. Selected days will highlight in green. Then, click
                  on "Confirm Recurring Dates" to confirm.
                </p>

                <RecurringDatePicker
                  onDateSelect={handleDateSelect}
                  selectedDates={recurringDates}
                />
              </div>
              <button
                type="button"
                className="form-button"
                onClick={handleConfirmRecurringDates}
              >
                Confirm Recurring Dates
              </button>
            </>
          )}
          {!showRecurringForm && (
            <button className="form-submit-button" type="submit">
              Add Class
            </button>
          )}
        </form>
      </div>
      <Calendar
        localizer={localizer}
        events={eventsForCalendar}
        onSelectEvent={handleSelectEvent}
        eventPropGetter={eventStyleGetter}
        style={{ height: "auto", minHeight: "1300px" }}
        views={["month"]}
        components={{
          event: CustomEvent
        }}
        startAccessor="start"
        endAccessor="end"
        date={currentDate} // Updated to use state
        onNavigate={(date) => setCurrentDate(date)} // Handle navigation
      />
      {/* Corrected JSX comment syntax */}
      {showEventDetails && selectedEvent && (
        <EventDetailsPopup
          event={selectedEvent}
          onEdit={handleEditEvent}
          onDelete={handleDeleteEvent}
          onClose={handleCloseDetails}
          userRole={userRole} // Pass userRole as a prop
          displayOnly={false} // Ensure this is set to false or omitted
        />
      )}
    </div>
  );
};

export default ClassScheduler;
