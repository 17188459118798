// RegistrationSuccess.js

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import "./RegistrationSuccess.css";

const RegistrationSuccess = () => {
  const { user } = useAuth();
  const [registeredClasses, setRegisteredClasses] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    try {
      const classes =
        JSON.parse(localStorage.getItem("registeredClasses")) || [];
      console.log("Retrieved registeredClasses from localStorage:", classes);
      setRegisteredClasses(classes);

      // Optional: Clear the registered classes from localStorage after fetching
      // localStorage.removeItem("registeredClasses");
    } catch (error) {
      console.error(
        "Error parsing registeredClasses from localStorage:",
        error
      );
      setRegisteredClasses([]);
    }
  }, []);

  // Helper function to format date and time
  const formatDateTime = (dateTimeString) => {
    if (!dateTimeString) return "No Date Provided";

    const date = new Date(dateTimeString);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "2-digit",
      hour12: true
    };
    return date.toLocaleString(undefined, options);
  };

  return (
    <div className="container text-center mt-5" style={{ marginTop: "100px" }}>
      <h1 className="success-title">Success!</h1>

      {/* Display User's Username or Email with Fallback */}
      <p>
        {user.username || user.email || "User"}, you have successfully joined
        the following classes:
      </p>

      {/* Check if there are registered classes */}
      {registeredClasses.length > 0 ? (
        <div className="row justify-content-center">
          {registeredClasses.map((item, index) => (
            <div key={index} className="col-md-4 mb-4">
              <div className="card h-100">
                {/* Display Class Image */}
                {item.image_url ? (
                  <img
                    src={item.image_url}
                    className="card-img-top"
                    alt={item.title || "Class Image"}
                  />
                ) : (
                  <img
                    src="https://via.placeholder.com/150" // Placeholder image
                    className="card-img-top"
                    alt="No Image Available"
                  />
                )}
                <div className="card-body">
                  <h5 className="card-title">
                    {item.title || "No Title Provided"}
                  </h5>
                  <p className="card-text">
                    <strong>Date:</strong> {formatDateTime(item.start_time)}
                  </p>
                  {/* Remove Price Display */}
                  {/* <p className="card-text">
                    <strong>Price:</strong> {item.price > 0 ? `$${parseFloat(item.price).toFixed(2)}` : "Free"}
                  </p> */}
                  {/* Add more details if needed */}
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p>No classes were registered.</p>
      )}

      {/* Action Buttons */}
      <div className="button-container mt-4">
        <button
          className="btn btn-primary me-2"
          onClick={() => navigate("/userProfile")}
        >
          Back to Profile
        </button>
        <button
          className="btn btn-secondary"
          onClick={() => navigate("/monthlySchedule")}
        >
          Back to Monthly Calendar
        </button>
      </div>
    </div>
  );
};

export default RegistrationSuccess;
