import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./AboutUs.css";
import "./BoardOfDirectors.css";
import "./PartnersSupporters.css";

const AboutUs = () => {
  const navigate = useNavigate();
  const { hash } = useLocation();
  const isLoggedIn = localStorage.getItem("token");

  useEffect(() => {
    // This effect runs when the component mounts and whenever the hash changes.
    if (hash) {
      const id = hash.replace("#", "");
      const element = document.getElementById(id);
      if (element) {
        // Using setTimeout to ensure the page layout has been updated
        setTimeout(() => element.scrollIntoView({ behavior: "smooth" }), 0);
      }
    }
  }, [hash]);

  return (
    <div className="about-us-container">
      {isLoggedIn && (
        <div className="back-to-profile-top mb-4">
          <button
            onClick={() => navigate("/userProfile")}
            className="btn btn-primary"
          >
            Back to Profile
          </button>
        </div>
      )}

      <div className="about-us-title">About Us</div>

      <section className="about-section our-story-section">
        <div className="headline-container">
          <div className="headline-lines"></div>
          <h2>Our Story</h2>
          <div className="headline-lines"></div>
        </div>
        <div className="large-text">
          <p>
            Pathways to Employment Corporation was founded in 2017 by Kim
            Christensen, who envisioned a future where her special needs
            daughter, Brynne, could lead a life of purpose. Unable to find a
            program that would meet her daughter's needs, she set out to create
            a program for her. She enlisted the help of like-minded parents and
            professionals, who envision a future where ALL people are employed,
            regardless of their disability.
            <br />
            <br />
            Located in Fair Oaks, CA, we are a community of parents, volunteers
            and board members dedicated to improving the lives of people with
            developmental disabilities by providing employment skills training.
          </p>
          <div className="about-section-photo-container">
            <img
              src="/Assets/Photos/AboutUsSection/AboutUs1.webp"
              alt="Our Story"
              className="about-section-image"
            />
          </div>
        </div>
      </section>

      <section className="about-section">
        <div className="headline-container">
          <div className="headline-lines"></div>
          <h2>Our Clients</h2>
          <div className="headline-lines"></div>
        </div>
        <div className="large-text">
          <p>
            Our clients span a wide range of independence levels. Some clients
            create products independently, others require an aide, sometimes
            prompting them hand-over-hand to make these unique products.
          </p>
          <div className="about-section-photo-container">
            <img
              src="/Assets/Photos/AboutUsSection/AboutUs2.webp"
              alt="Our Clients"
              className="about-section-image"
            />
          </div>
        </div>
      </section>

      <section className="about-section">
        <div className="headline-container">
          <div className="headline-lines"></div>
          <h2>Our Passion & Purpose</h2>
          <div className="headline-lines"></div>
        </div>
        <div className="large-text">
          <p>
            Our organization empowers young adults with developmental
            disabilities by giving them the skills they need for employment and
            volunteer opportunities. Each of our clients takes pride in their
            work, helping to foster a sense of purpose, community &
            independence.
          </p>
          <div className="about-section-photo-container">
            <img
              src="/Assets/Photos/AboutUsSection/AboutUs3.webp"
              alt="Our Passion & Purpose"
              className="about-section-image"
            />
          </div>
        </div>
      </section>

      <section id="board" className="about-section">
        <div className="headline-container">
          <div className="headline-lines"></div>
          <h2>Board of Directors</h2>
          <div className="headline-lines"></div>
        </div>

        <div className="board-members-container">
          <div className="board-member">
            <img
              src="/Assets/Photos/BoardOfDirectors/kim.webp"
              alt="Kim Christensen"
              className="board-member-image"
            />
            <p className="board-member-name">Kim Christensen</p>
            <p className="board-member-title">Board President</p>
          </div>

          <div className="board-member">
            <img
              src="/Assets/Photos/BoardOfDirectors/natalie.webp"
              alt="Natalie Cooper"
              className="board-member-image"
            />
            <p className="board-member-name">Natalie Cooper</p>
            <p className="board-member-title">Treasurer</p>
          </div>

          <div className="board-member">
            <img
              src="/Assets/Photos/BoardOfDirectors/supersue.webp"
              alt="Sue Samuel"
              className="board-member-image"
            />
            <p className="board-member-name">Sue Samuel</p>
            <p className="board-member-title">Secretary</p>
          </div>

          <div className="board-member">
            <img
              src="/Assets/Photos/BoardOfDirectors/emily.webp"
              alt="Emily Smith"
              className="board-member-image"
            />
            <p className="board-member-name">Emily Smith</p>
            <p className="board-member-title">Director</p>
          </div>

          <div className="board-member">
            <img
              src="/Assets/Photos/BoardOfDirectors/roberta.webp"
              alt="Roberta Dunn"
              className="board-member-image"
            />
            <p className="board-member-name">Roberta Dunn</p>
            <p className="board-member-title">Director</p>
          </div>

          <div className="board-member">
            <img
              src="/Assets/Photos/BoardOfDirectors/jennifer.webp"
              alt="Jennifer Kaiser"
              className="board-member-image"
            />
            <p className="board-member-name">Jennifer Kaiser</p>
            <p className="board-member-title">Director</p>
          </div>
        </div>
      </section>

      <section id="partners" className="about-section">
        <div className="headline-container">
          <div className="headline-lines"></div>
          <h2>Partners & Supporters</h2>
          <div className="headline-lines"></div>
        </div>
        <div className="partners-supporters-container">
          <div className="partner-supporter">
            <a
              href="https://auburnfoodcloset.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/Assets/Photos/PartnersSupporters/Auburn.webp"
                alt="Auburn Interfaith Food Closet"
              />
            </a>
          </div>
          <div className="partner-supporter">
            <img
              src="/Assets/Photos/PartnersSupporters/Eiro.webp"
              alt="Eiro Bridge"
            />
          </div>
          <div className="partner-supporter">
            <a
              href="https://www.stjameslincoln.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/Assets/Photos/PartnersSupporters/StJames.webp"
                alt="St James Episcopal Church"
              />
            </a>
          </div>
          <div className="partner-supporter">
            <a
              href="https://www.invitedclubs.com/clubs/granite-bay-golf-club"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/Assets/Photos/PartnersSupporters/GraniteBay.webp"
                alt="Granite Bay Golf Club"
              />
            </a>
          </div>
          <div className="partner-supporter">
            <a
              href="https://robertasavagelaw.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/Assets/Photos/PartnersSupporters/Law.webp"
                alt="Law Office of Roberta S. Savage"
              />
            </a>
          </div>
          <div className="partner-supporter">
            <a
              href="https://placerspca.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/Assets/Photos/PartnersSupporters/Placer.webp"
                alt="Placer SPCA"
              />
            </a>
          </div>
          <div className="partner-supporter">
            <a
              href="https://tlcinctherapies.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/Assets/Photos/PartnersSupporters/TLC.webp"
                alt="Therapeutic Language Clinic, Inc. (TLC)"
              />
            </a>
          </div>
          {/* New Partner: Beneto Foundation */}
          <div className="partner-supporter">
            <a
              href="https://benetofoundation.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="https://benetofoundation.org/wp-content/uploads/2024/02/BENETO-FOUNDATION-logo-final-225x56.png"
                alt="Beneto Foundation"
                className="beneto-logo"
              />
            </a>
          </div>
        </div>
      </section>
      {isLoggedIn && (
        <div className="back-to-profile-bottom mt-4">
          <button
            onClick={() => navigate("/userProfile")}
            className="btn btn-primary"
          >
            Back to Profile
          </button>
        </div>
      )}
    </div>
  );
};

export default AboutUs;
