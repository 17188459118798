// ParticipationFormLayout.js

import React from "react";
import "./ParticipationForm.css";

const ParticipationFormLayout = ({
  formData,
  handleChange,
  handleSubmit,
  isSubmitting
}) => {
  return (
    <div className="container my-5 custom-margin-top">
      <h1 className="text-center mb-4">
        Participation & Emergency Contact Form
      </h1>

      <form onSubmit={handleSubmit}>
        {/* Participation Section */}
        <h2 className="mb-3">Participation Information</h2>

        {/* First Row: First Name and Last Name */}
        <div className="row mb-3">
          <div className="col-md-6">
            <label htmlFor="firstName" className="form-label">
              First Name <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              name="firstName"
              id="firstName"
              placeholder="First Name"
              className="form-control"
              onChange={handleChange}
              value={formData.firstName}
              required
            />
          </div>

          <div className="col-md-6">
            <label htmlFor="lastName" className="form-label">
              Last Name <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              name="lastName"
              id="lastName"
              placeholder="Last Name"
              className="form-control"
              onChange={handleChange}
              value={formData.lastName}
              required
            />
          </div>
        </div>

        {/* Second Row: Age and Date of Birth */}
        <div className="row mb-3">
          <div className="col-md-6">
            <label htmlFor="age" className="form-label">
              Age <span className="text-danger">*</span>
            </label>
            <input
              type="number"
              name="age"
              id="age"
              placeholder="Age"
              className="form-control"
              onChange={handleChange}
              value={formData.age}
              required
              min="0"
            />
          </div>

          <div className="col-md-6">
            <label htmlFor="dateOfBirth" className="form-label">
              Date of Birth <span className="text-danger">*</span>
            </label>
            <input
              type="date"
              name="dateOfBirth"
              id="dateOfBirth"
              className="form-control"
              onChange={handleChange}
              value={formData.dateOfBirth}
              required
            />
          </div>
        </div>

        {/* Third Row: Address (Long Input) */}
        <div className="row mb-3">
          <div className="col-12">
            <label htmlFor="address" className="form-label">
              Address <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              name="address"
              id="address"
              placeholder="Address"
              className="form-control"
              onChange={handleChange}
              value={formData.address}
              required
            />
          </div>
        </div>

        {/* Fourth Row: Email and Phone */}
        <div className="row mb-3">
          <div className="col-md-6">
            <label htmlFor="email" className="form-label">
              Email <span className="text-danger">*</span>
            </label>
            <input
              type="email"
              name="email"
              id="email"
              placeholder="Email"
              className="form-control"
              onChange={handleChange}
              value={formData.email}
              required
            />
          </div>

          <div className="col-md-6">
            <label htmlFor="phone" className="form-label">
              Phone <span className="text-danger">*</span>
            </label>
            <input
              type="tel"
              name="phone"
              id="phone"
              placeholder="Phone (e.g., 123-456-7890)"
              className="form-control"
              onChange={handleChange}
              value={formData.phone}
              required
            />
          </div>
        </div>

        {/* Divider */}
        <hr />

        {/* Consent Section */}
        <h2 className="mb-3">Consent and Waivers</h2>

        {/* Responsible Adult Consent */}
        <div className="mb-3">
          <div className="form-check">
            <input
              type="checkbox"
              name="responsibleAdultConsent"
              id="responsibleAdultConsent"
              className="form-check-input custom-checkbox"
              checked={formData.responsibleAdultConsent || false}
              onChange={handleChange}
              required
            />
            <label
              className="form-check-label"
              htmlFor="responsibleAdultConsent"
            >
              I understand that a responsible adult must attend with I/DD
              participant, unless otherwise confirmed with Pathway To
              Employments Board.
            </label>
          </div>
        </div>

        {/* Photo Consent */}
        <div className="mb-3">
          <div className="form-check">
            <input
              type="checkbox"
              name="photo_permission"
              id="photo_permission"
              className="form-check-input custom-checkbox"
              checked={formData.photo_permission}
              onChange={handleChange}
            />
            <label className="form-check-label" htmlFor="photo_permission">
              I consent to the use of photos on social media.
            </label>
          </div>
        </div>

        {/* Photo Consent for Marketing Purposes */}
        <div className="mb-3">
          <div className="form-check">
            <input
              type="checkbox"
              name="photo_consent"
              id="photo_consent"
              className="form-check-input custom-checkbox"
              checked={formData.photo_consent || false}
              onChange={handleChange}
            />
            <label className="form-check-label" htmlFor="photo_consent">
              I consent to have my photos taken and used for marketing purposes.
            </label>
          </div>
        </div>

        {/* Divider */}
        <hr />

        {/* Parent/Legal Guardian Consent Section */}
        <h2 className="mb-3">Parent/Legal Guardian Consent</h2>
        <p className="text-justify">
          Parent/Legal Guardian Consent and Waiver of Liability:
          <br />
          <br />
          I understand that Pathways to Employment programs and related
          activities carry inherent risks. These risks can result from the
          activities, my actions or inactions, or those of Pathways, its team,
          volunteers, or others at program sites. I take full responsibility for
          any bodily injury, death, or property damage during my presence at
          Pathways' program sites or participation in its activities, regardless
          of the cause.
          <br />
          <br />
          In consideration of my participation, I waive and release any claims
          against Pathways to Employment, Landowners, Funders, and their
          respective directors, officers, agents, employees, volunteers, and
          affiliates (collectively, the “Released Parties”) for any liability,
          loss, damages, claims, expenses, or attorney fees resulting from my
          presence at a Pathways program site or participation in related
          activities, except for injuries caused by gross negligence or willful
          misconduct of a Released Party. I agree not to sue any of the Released
          Parties for these waived and released claims.
          <br />
          <br />
          If I am a parent/legal guardian of a minor Participant, I acknowledge
          and affirm that I assume these risks and enter into this waiver and
          release on behalf of the Participant.
        </p>

        {/* Parent/Guardian Consent Checkbox */}
        <div className="mb-3 row">
          <div className="col-md-6">
            <div className="form-check">
              <input
                type="checkbox"
                name="parentGuardianConsent"
                id="parentGuardianConsent"
                className="form-check-input custom-checkbox"
                checked={formData.parentGuardianConsent || false}
                onChange={handleChange}
                required
              />
              <label
                className="form-check-label"
                htmlFor="parentGuardianConsent"
              >
                Yes, I understand
              </label>
            </div>
          </div>
        </div>

        {/* Parent/Guardian Name Input */}
        <p className="text-justify">
          By typing my First and Last Name, I acknowledge and represent that I
          have read the foregoing waiver of liability, understand it in the
          language it is written, and sign it voluntarily as my own free act and
          deed as well as the participant minor or conserved waiver as their
          parent/legal guardian.
        </p>
        <div className="row mb-3">
          <div className="col-12">
            <label htmlFor="parentGuardianName" className="form-label">
              Parent/Guardian Name <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              name="parentGuardianName"
              id="parentGuardianName"
              placeholder="Type your First and Last Name as Parent/Guardian"
              className="form-control"
              onChange={handleChange}
              value={formData.parentGuardianName}
              required
            />
          </div>
        </div>

        {/* Divider */}
        <hr />

        {/* Emergency Contact Section */}
        <h2 className="mb-3">Emergency Contact Information</h2>

        {/* First Emergency Contact */}
        <div className="row mb-3">
          <div className="col-md-6">
            <label htmlFor="emergencyContact1Name" className="form-label">
              Contact 1 Name <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              name="emergencyContact1Name"
              id="emergencyContact1Name"
              placeholder="Contact 1 Name"
              className="form-control"
              onChange={handleChange}
              value={formData.emergencyContact1Name}
              required
            />
          </div>

          <div className="col-md-6">
            <label htmlFor="emergencyContact1Phone" className="form-label">
              Contact 1 Phone Number <span className="text-danger">*</span>
            </label>
            <input
              type="tel"
              name="emergencyContact1Phone"
              id="emergencyContact1Phone"
              placeholder="Contact 1 Phone Number"
              className="form-control"
              onChange={handleChange}
              value={formData.emergencyContact1Phone}
              required
            />
          </div>
        </div>

        {/* Second Emergency Contact */}
        <div className="row mb-3">
          <div className="col-md-6">
            <label htmlFor="emergencyContact2Name" className="form-label">
              Contact 2 Name <span className="text-danger">*</span>
            </label>
            <input
              type="text"
              name="emergencyContact2Name"
              id="emergencyContact2Name"
              placeholder="Contact 2 Name"
              className="form-control"
              onChange={handleChange}
              value={formData.emergencyContact2Name}
              required
            />
          </div>

          <div className="col-md-6">
            <label htmlFor="emergencyContact2Phone" className="form-label">
              Contact 2 Phone Number <span className="text-danger">*</span>
            </label>
            <input
              type="tel"
              name="emergencyContact2Phone"
              id="emergencyContact2Phone"
              placeholder="Contact 2 Phone Number"
              className="form-control"
              onChange={handleChange}
              value={formData.emergencyContact2Phone}
              required
            />
          </div>
        </div>

        {/* Disability and Allergies Section */}
        <div className="row mb-3">
          {/* Disability */}
          <div className="col-md-6">
            <label htmlFor="disability" className="form-label">
              Disability
            </label>
            <input
              type="text"
              name="disability"
              id="disability"
              placeholder="Disability"
              className="form-control"
              onChange={handleChange}
              value={formData.disability}
            />
          </div>

          {/* Allergies */}
          <div className="col-md-6">
            <label htmlFor="allergies" className="form-label">
              Allergies
            </label>
            <input
              type="text"
              name="allergies"
              id="allergies"
              placeholder="Allergies"
              className="form-control"
              onChange={handleChange}
              value={formData.allergies}
            />
          </div>
        </div>

        {/* Behaviors Section */}
        <div className="row mb-3">
          <div className="col-12">
            <label htmlFor="behaviors" className="form-label">
              Behaviors
            </label>
            <input
              type="text"
              name="behaviors"
              id="behaviors"
              placeholder="Behaviors"
              className="form-control"
              onChange={handleChange}
              value={formData.behaviors}
            />
          </div>
        </div>

        {/* Conserved Section */}
        <div className="mb-3">
          <label className="form-label d-block">Conserved:</label>
          <div className="form-check form-check-inline">
            <input
              type="radio"
              name="conserved"
              id="conservedYes"
              value="true"
              className="form-check-input"
              onChange={handleChange}
              checked={formData.conserved === true}
            />
            <label htmlFor="conservedYes" className="form-check-label">
              Yes
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              type="radio"
              name="conserved"
              id="conservedNo"
              value="false"
              className="form-check-input"
              onChange={handleChange}
              checked={formData.conserved === false}
            />
            <label htmlFor="conservedNo" className="form-check-label">
              No
            </label>
          </div>
        </div>

        {/* Preferred Hospital and Primary Physician Section */}
        <div className="row mb-3">
          {/* Preferred Hospital */}
          <div className="col-md-6">
            <label htmlFor="preferredHospital" className="form-label">
              Preferred Hospital
            </label>
            <input
              type="text"
              name="preferredHospital"
              id="preferredHospital"
              placeholder="Preferred Hospital"
              className="form-control"
              onChange={handleChange}
              value={formData.preferredHospital}
            />
          </div>

          {/* Primary Physician */}
          <div className="col-md-6">
            <label htmlFor="primaryPhysician" className="form-label">
              Primary Physician
            </label>
            <input
              type="text"
              name="primaryPhysician"
              id="primaryPhysician"
              placeholder="Primary Physician"
              className="form-control"
              onChange={handleChange}
              value={formData.primaryPhysician}
            />
          </div>
        </div>

        {/* Alta Service Coordinator and Service Coordinator Phone Number Section */}
        <div className="row mb-3">
          {/* Alta Service Coordinator */}
          <div className="col-md-6">
            <label htmlFor="serviceCoordinator" className="form-label">
              Alta Service Coordinator
            </label>
            <input
              type="text"
              name="serviceCoordinator"
              id="serviceCoordinator"
              placeholder="Alta Service Coordinator"
              className="form-control"
              onChange={handleChange}
              value={formData.serviceCoordinator}
            />
          </div>

          {/* Service Coordinator Phone Number */}
          <div className="col-md-6">
            <label htmlFor="serviceCoordinatorPhone" className="form-label">
              Service Coordinator Phone Number
            </label>
            <input
              type="tel"
              name="serviceCoordinatorPhone"
              id="serviceCoordinatorPhone"
              placeholder="Phone Number"
              className="form-control"
              onChange={handleChange}
              value={formData.serviceCoordinatorPhone}
            />
          </div>
        </div>

        {/* Submit Button */}
        <div className="mb-3">
          <button
            type="submit"
            className="btn btn-primary"
            disabled={isSubmitting}
          >
            {isSubmitting ? "Submitting..." : "Submit Form"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ParticipationFormLayout;
