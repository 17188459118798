// Services.js

import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from "./Modal";
import "./Services.css";
import "./Classes.css";
import { useNavigate } from "react-router-dom";
import DefaultImage1 from "../../Assets/DefaultImage.webp";
import DefaultImage2 from "../../Assets/DefaultImage2.png";
import DefaultImage3 from "../../Assets/DefaultImage3.png";
import DefaultImage4 from "../../Assets/DefaultImage4.jpg";
import DefaultImage5 from "../../Assets/DefaultImage5.jpg";

const Services = () => {
  const [activeCategory, setActiveCategory] = useState("All");
  const [eventMap, setEventMap] = useState({});
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isLoggedIn = localStorage.getItem("token");
  const navigate = useNavigate();

  const defaultImages = [
    DefaultImage1,
    DefaultImage2,
    DefaultImage3,
    DefaultImage4,
    DefaultImage5
  ];

  useEffect(() => {
    const eventsEndpoint =
      "https://www.ptemployment.org/api/classes/class-schedules";
    axios
      .get(eventsEndpoint)
      .then((response) => {
        console.log("API response:", response.data);
        const groupedEvents = groupEventsByTitle(response.data);
        console.log("Grouped events:", groupedEvents);
        setEventMap(groupedEvents);
      })
      .catch((error) => {
        console.error("Error fetching events:", error);
      });
  }, []);

  const groupEventsByTitle = (eventsArray) => {
    return eventsArray.reduce((acc, event) => {
      const title = event.title.trim();
      if (!acc[title]) {
        acc[title] = {
          ...event,
          dates: [event.start_time],
          datesToEvents: {
            [event.start_time]: event
          }
        };
      } else {
        acc[title].dates.push(event.start_time);
        acc[title].datesToEvents[event.start_time] = event;
      }
      return acc;
    }, {});
  };

  const getEventsToDisplay = () => {
    const eventsArray = Object.values(eventMap);
    if (activeCategory === "All") {
      return eventsArray;
    }
    return eventsArray.filter((event) => event.category === activeCategory);
  };

  const openModal = (eventItem) => {
    console.log("Selected event for modal:", eventItem);
    setSelectedEvent(eventItem);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedEvent(null);
  };

  return (
    <div>
      <div className="back-to-profile-container mb-4">
        <div className="headline-container">
          <div className="headline-lines"></div>
          <h2>Class Description</h2>
          <div className="headline-lines"></div>
        </div>
        {isLoggedIn ? (
          <button
            onClick={() => navigate("/userProfile")}
            className="btn btn-primary"
          >
            Back to Profile
          </button>
        ) : (
          <p className="login-message">
            You must be logged in to sign up for classes.{" "}
            <span
              onClick={() => navigate("/login")}
              style={{ color: "#007bff", cursor: "pointer" }}
            >
              Login here
            </span>
          </p>
        )}
      </div>

      <div className="class-container">
        {getEventsToDisplay().map((eventItem) => (
          <div
            key={eventItem.id}
            className="class-item"
            onClick={() => openModal(eventItem)}
          >
            <img
              key={eventItem.image_url} // Ensure the image updates when the URL changes
              src={eventItem.image_url}
              alt={eventItem.title}
              className="class-image"
              onError={(e) => {
                e.target.onerror = null;
                const index = eventItem.id % defaultImages.length;
                e.target.src = defaultImages[index];
              }}
            />
            <h3>{eventItem.title}</h3>
            <p>
              Price: {eventItem.price === 0 ? "Free" : `$${eventItem.price}`}
            </p>
          </div>
        ))}
      </div>

      <section className="services-section">
        <div className="headline-container">
          <div className="headline-lines"></div>
          <h2>Monthly Schedule</h2>
          <div className="headline-lines"></div>
        </div>
        <div className="large-text">
          <p>
            Check out our{" "}
            <span
              onClick={() => navigate("/monthlySchedule")}
              style={{ color: "#007bff", cursor: "pointer" }}
            >
              Monthly Schedule
            </span>{" "}
            for the latest classes and events.
          </p>
        </div>
      </section>
      {isModalOpen && <Modal classInfo={selectedEvent} onClose={closeModal} />}
      {isLoggedIn && (
        <div className="back-to-profile-bottom mt-4">
          <button
            onClick={() => navigate("/userProfile")}
            className="btn btn-primary"
          >
            Back to Profile
          </button>
        </div>
      )}
    </div>
  );
};

export default Services;
