import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Invoicing.css";
import moment from "moment";

// **Define the Backend Base URL**
const API_BASE_URL =
  window.location.hostname === "localhost"
    ? "http://localhost:3000/api" // Development backend
    : "/api"; // Production backend

const Invoicing = () => {
  // ---------------------------------------
  // **State Variables**
  // ---------------------------------------
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [events, setEvents] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(moment().startOf("month"));
  const [expandedInvoiceItems, setExpandedInvoiceItems] = useState({});
  const [isEditingBillingInfo, setIsEditingBillingInfo] = useState(false);
  const [isConfirmingEmailAll, setIsConfirmingEmailAll] = useState(false);

  const [billToInfo, setBillToInfo] = useState({
    name: "N/A",
    address: "N/A",
    phone: "N/A",
    email: "N/A"
  });

  const [showConfirmSave, setShowConfirmSave] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const location = useLocation();
  const token = location.state?.token || localStorage.getItem("token");

  // ---------------------------------------
  // **Data Fetching on Component Mount**
  // ---------------------------------------
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        if (!token) {
          console.error("No JWT token found. Authentication failed.");
          return;
        }

        const response = await axios.get(`${API_BASE_URL}/users`, {
          // **Updated**
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        const usersWithFullNames = await Promise.all(
          response.data.map(async (user) => {
            const userId = user.user_id || user.id;
            const participationResponse = await axios.get(
              `${API_BASE_URL}/check-form-completion?user_id=${userId}`, // **Updated**
              {
                headers: {
                  Authorization: `Bearer ${token}`
                }
              }
            );
            const { firstName, lastName } = participationResponse.data;
            return {
              ...user,
              first_name: firstName,
              last_name: lastName
            };
          })
        );

        setUsers(usersWithFullNames);
      } catch (error) {
        console.error("Error fetching users or participation details:", error);
      }
    };

    fetchUsers();
  }, [token]);

  // ---------------------------------------
  // **User Selection and Data Fetching**
  // ---------------------------------------
  const handleUserSelect = async (user) => {
    const userId = user.user_id || user.id;
    console.log("Selected User ID:", userId);

    const selectedUserWithFullName = {
      ...user,
      fullName:
        `${user.first_name || ""} ${user.last_name || ""}`.trim() || user.email
    };
    setSelectedUser(selectedUserWithFullName);

    if (!token) {
      console.error("No valid JWT token.");
      return;
    }

    try {
      // Fetch events specific to the selected user
      const eventsResponse = await axios.get(
        `${API_BASE_URL}/users/${userId}/events`,
        {
          // **Updated**
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      console.log("Fetched Events for User:", eventsResponse.data);
      const updatedEvents = eventsResponse.data.map((event) => ({
        ...event,
        billing_code: event.billing_code || "331", // Set default billing_code if not present
        billing_hours:
          (new Date(event.end_time) - new Date(event.start_time)) /
          (1000 * 60 * 60) // Calculate billing hours
      }));

      setEvents(updatedEvents);

      // Fetch Bill To information for the selected user
      const billToResponse = await axios.get(
        `${API_BASE_URL}/invoicing/bill-to/${userId}`, // **Updated**
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      const fetchedBillToInfo = billToResponse.data || {
        name: "N/A",
        address: "N/A",
        phone: "N/A",
        email: "N/A"
      };

      setBillToInfo(fetchedBillToInfo);

      console.log("Fetched Bill To Info:", fetchedBillToInfo);
    } catch (error) {
      console.error("Error fetching events or Bill To info:", error);
    }
  };

  // ---------------------------------------
  // **Month Navigation**
  // ---------------------------------------
  const handleMonthChange = (increment) => {
    setCurrentMonth(currentMonth.clone().add(increment, "months"));
  };

  // ---------------------------------------
  // **Event Filtering for Current Month**
  // ---------------------------------------
  const getEventsForCurrentMonth = () => {
    return events.filter((event) => {
      const eventMonth = moment(event.start_time).startOf("month");
      return eventMonth.isSame(currentMonth, "month");
    });
  };

  // ---------------------------------------
  // **Invoice Preview Functionality**
  // ---------------------------------------
  const previewInvoice = async () => {
    if (!token) {
      alert("No JWT token found. Authentication failed.");
      return;
    }

    try {
      const userId = selectedUser?.user_id || selectedUser?.id;

      if (!userId) {
        alert("No user selected. Please select a user to preview the invoice.");
        return;
      }

      // Derive billingMonth and billingYear from currentMonth
      const billingMonth = currentMonth.month() + 1; // moment months are 0-indexed
      const billingYear = currentMonth.year();

      // Prepare the request payload with additional user info
      const payload = {
        user_id: userId,
        billingMonth: billingMonth,
        billingYear: billingYear,
        first_name: selectedUser.first_name,
        last_name: selectedUser.last_name,
        address: billToInfo.address,
        phone: billToInfo.phone,
        email: selectedUser.email
      };

      // Request to generate a PDF preview
      const response = await axios.post(
        `${API_BASE_URL}/invoicing/invoice-pdf-preview`, // **Updated**
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json"
          },
          responseType: "blob" // Expecting a blob response for PDF
        }
      );

      // Create a blob URL to display the PDF
      const pdfBlob = new Blob([response.data], { type: "application/pdf" });
      const pdfUrl = window.URL.createObjectURL(pdfBlob);
      window.open(pdfUrl, "_blank"); // Open in a new tab
    } catch (error) {
      console.error("Error previewing invoice:", error);
      alert(
        error.response?.data?.error ||
          "There was an error previewing the invoice. Please try again."
      );
    }
  };

  // ---------------------------------------
  // **Invoice Item Expansion**
  // ---------------------------------------
  const toggleInvoiceItemExpand = (itemId) => {
    setExpandedInvoiceItems((prev) => ({
      ...prev,
      [itemId]: !prev[itemId]
    }));
  };

  // ---------------------------------------
  // **Handling Invoice Item Changes**
  // ---------------------------------------
  const handleInvoiceItemChange = (eventId, itemIndex, field, value) => {
    const updatedEvents = events.map((event) => {
      if (event.event_id === eventId) {
        const updatedEvent = { ...event };
        updatedEvent[field] = value;
        return updatedEvent;
      }
      return event;
    });

    setEvents(updatedEvents);
  };

  // ---------------------------------------
  // **Saving Individual Event Changes**
  // ---------------------------------------
  const saveChanges = async (event) => {
    const confirmed = window.confirm(
      "Are you sure you want to make this specific change?"
    );
    if (!confirmed) return;

    try {
      await axios.put(`${API_BASE_URL}/events/${event.event_id}`, event, {
        // **Updated**
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      alert("Event updated successfully.");
    } catch (error) {
      console.error("Error updating event:", error);
      alert(
        error.response?.data?.error ||
          "There was an error updating the event. Please try again."
      );
    }
  };

  // ---------------------------------------
  // **Sending Invoice to Selected User**
  // ---------------------------------------
  const sendInvoiceToUser = async () => {
    try {
      if (!selectedUser) {
        alert("No user selected. Please select a user to send the invoice.");
        return;
      }

      const userId = selectedUser.user_id || selectedUser.id;

      // Derive billingMonth and billingYear from currentMonth
      const billingMonth = currentMonth.month() + 1; // moment months are 0-indexed
      const billingYear = currentMonth.year();

      // Prepare the request payload with additional user info
      const payload = {
        user_id: userId,
        billingMonth: billingMonth,
        billingYear: billingYear,
        first_name: selectedUser.first_name,
        last_name: selectedUser.last_name,
        address: billToInfo.address,
        phone: billToInfo.phone,
        email: selectedUser.email
      };

      // **Add this line to log the payload**
      console.log("Sending Invoice Payload:", payload);

      // Request to send the invoice via email
      await axios.post(
        `${API_BASE_URL}/invoicing/send-invoice-email`,
        payload,
        {
          // **Updated**
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        }
      );

      alert(
        `Invoice for ${selectedUser.first_name} ${
          selectedUser.last_name
        } sent successfully for ${currentMonth.format("MMMM YYYY")}.`
      );
    } catch (error) {
      console.error("Error sending invoice email:", error);
      alert(
        error.response?.data?.error ||
          "There was an error sending the invoice email. Please try again."
      );
    }
  };

  // ---------------------------------------
  // **Saving Billing Information**
  // ---------------------------------------
  const handleSaveBillingInfoClick = () => {
    setShowConfirmSave(true);
  };

  const handleConfirmSaveBillingInfo = async () => {
    try {
      const userId = selectedUser.user_id || selectedUser.id;
      const payload = {
        first_name: selectedUser.first_name,
        last_name: selectedUser.last_name,
        billing_code: selectedUser.billing_code,
        address: billToInfo.address, // Added Address
        phone: billToInfo.phone,
        email: selectedUser.email
      };

      await axios.put(`${API_BASE_URL}/users/${userId}/billing-info`, payload, {
        // **Updated**
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json"
        }
      });

      setShowSuccessMessage(true);
      setShowConfirmSave(false);
      setIsEditingBillingInfo(false);
    } catch (error) {
      console.error("Error saving Billing Information:", error);
      setErrorMessage(
        error.response?.data?.error ||
          "There was an error saving the billing information. Please try again."
      );
      setShowErrorMessage(true);
      setShowConfirmSave(false);
    }
  };

  const handleCancelSaveBillingInfo = () => {
    setShowConfirmSave(false);
  };

  // ---------------------------------------
  // **Handling Email All Users**
  // ---------------------------------------
  const handleEmailAllClick = () => {
    setIsConfirmingEmailAll(true);
  };

  const handleSendEmailAll = async () => {
    try {
      // Close the confirmation dialog
      setIsConfirmingEmailAll(false);

      // Derive billingMonth and billingYear from currentMonth
      const billingMonth = currentMonth.month() + 1; // moment months are 0-indexed
      const billingYear = currentMonth.year();

      // Prepare the request payload
      const payload = {
        billingMonth: billingMonth,
        billingYear: billingYear
      };

      // Send the POST request to the backend
      const response = await axios.post(
        `${API_BASE_URL}/invoicing/send-invoice-email-all`, // **Updated**
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json"
          }
        }
      );

      // Show success message
      alert(response.data.message);
    } catch (error) {
      console.error("Error sending invoices to all users:", error);
      alert(
        error.response?.data?.error ||
          "There was an error sending invoices to all users. Please try again."
      );
    }
  };

  const handleCancelEmailAll = () => {
    setIsConfirmingEmailAll(false);
  };

  // ---------------------------------------
  // **Render Method**
  // ---------------------------------------
  return (
    <div className="invoicing-container container mt-4">
      <h2 className="mb-4">Invoicing</h2>

      {/* ---------------------------------------
          **Email All Users Button**
      --------------------------------------- */}
      <div className="email-all-users mb-4">
        <button className="btn btn-danger w-100" onClick={handleEmailAllClick}>
          Email All Users for {currentMonth.format("MMMM YYYY")}
        </button>
      </div>

      {/* ---------------------------------------
          **Confirmation Dialog for Email All**
      --------------------------------------- */}
      {isConfirmingEmailAll && (
        <div className="confirmation-dialog card card-body mb-4">
          <p>
            This will send invoices to all users for{" "}
            {currentMonth.format("MMMM YYYY")}.
          </p>
          <button className="btn btn-success mr-2" onClick={handleSendEmailAll}>
            Send
          </button>
          <button className="btn btn-secondary" onClick={handleCancelEmailAll}>
            Cancel
          </button>
        </div>
      )}

      {/* ---------------------------------------
          **Success and Error Messages**
      --------------------------------------- */}
      {showSuccessMessage && (
        <div
          className="alert alert-success alert-dismissible fade show"
          role="alert"
        >
          Billing information updated successfully.
          <button
            type="button"
            className="close"
            onClick={() => setShowSuccessMessage(false)}
          >
            <span>&times;</span>
          </button>
        </div>
      )}
      {showErrorMessage && (
        <div
          className="alert alert-danger alert-dismissible fade show"
          role="alert"
        >
          {errorMessage}
          <button
            type="button"
            className="close"
            onClick={() => setShowErrorMessage(false)}
          >
            <span>&times;</span>
          </button>
        </div>
      )}

      {/* ---------------------------------------
          **Users List**
      --------------------------------------- */}
      <div className="user-list mb-4">
        <h3>Users</h3>
        <ul className="list-group">
          {users.map((user) => {
            const displayName =
              user.fullName ||
              user.name?.trim() ||
              `${user.first_name || ""} ${user.last_name || ""}`.trim() ||
              user.email ||
              "Unnamed User";

            return (
              <li
                key={user.user_id || user.id}
                className="list-group-item list-group-item-action"
                onClick={() => handleUserSelect(user)}
              >
                <span>{displayName}</span>
              </li>
            );
          })}
        </ul>
      </div>

      {/* ---------------------------------------
          **Selected User's Invoices and Actions**
      --------------------------------------- */}
      {selectedUser && (
        <div className="user-invoices">
          <h3>{selectedUser.fullName || selectedUser.email}'s Events</h3>

          {/* ---------------------------------------
              **Edit Billing Information Button**
          --------------------------------------- */}
          <button
            className="btn btn-warning mb-3"
            onClick={() => setIsEditingBillingInfo(!isEditingBillingInfo)}
          >
            {isEditingBillingInfo
              ? `Cancel Edit`
              : `Edit ${selectedUser.first_name} ${selectedUser.last_name}'s Billing Information`}
          </button>

          {/* ---------------------------------------
              **Billing Information Edit Form**
          --------------------------------------- */}
          {isEditingBillingInfo && (
            <div className="card card-body mb-3">
              <h4>Edit Billing Information</h4>

              {/* ---------------------------------------
                  **Success and Error Alerts within Form**
              --------------------------------------- */}
              {showSuccessMessage && (
                <div
                  className="alert alert-success alert-dismissible fade show"
                  role="alert"
                >
                  Billing information updated successfully.
                  <button
                    type="button"
                    className="close"
                    onClick={() => setShowSuccessMessage(false)}
                  >
                    <span>&times;</span>
                  </button>
                </div>
              )}
              {showErrorMessage && (
                <div
                  className="alert alert-danger alert-dismissible fade show"
                  role="alert"
                >
                  {errorMessage}
                  <button
                    type="button"
                    className="close"
                    onClick={() => setShowErrorMessage(false)}
                  >
                    <span>&times;</span>
                  </button>
                </div>
              )}

              {/* ---------------------------------------
                  **Billing Information Fields**
              --------------------------------------- */}
              <div className="form-group">
                <label>First Name</label>
                <input
                  type="text"
                  value={selectedUser.first_name || ""}
                  onChange={(e) =>
                    setSelectedUser({
                      ...selectedUser,
                      first_name: e.target.value
                    })
                  }
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label>Last Name</label>
                <input
                  type="text"
                  value={selectedUser.last_name || ""}
                  onChange={(e) =>
                    setSelectedUser({
                      ...selectedUser,
                      last_name: e.target.value
                    })
                  }
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label>Address</label>
                <input
                  type="text"
                  value={billToInfo.address || ""}
                  onChange={(e) =>
                    setBillToInfo({ ...billToInfo, address: e.target.value })
                  }
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label>Phone Number</label>
                <input
                  type="text"
                  value={billToInfo.phone || ""}
                  onChange={(e) =>
                    setBillToInfo({ ...billToInfo, phone: e.target.value })
                  }
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label>Email</label>
                <input
                  type="email"
                  value={selectedUser.email || ""}
                  onChange={(e) =>
                    setSelectedUser({ ...selectedUser, email: e.target.value })
                  }
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label>Billing Code</label>
                <input
                  type="text"
                  value={selectedUser.billing_code || ""}
                  onChange={(e) =>
                    setSelectedUser({
                      ...selectedUser,
                      billing_code: e.target.value
                    })
                  }
                  className="form-control"
                />
              </div>

              {/* ---------------------------------------
                  **Save Billing Information Button**
              --------------------------------------- */}
              <button
                className="btn btn-success mt-3"
                onClick={handleSaveBillingInfoClick}
              >
                Save Billing Information
              </button>

              {/* ---------------------------------------
                  **Confirmation Dialog for Save Billing Info**
              --------------------------------------- */}
              {showConfirmSave && (
                <div className="confirmation-message card card-body mt-3">
                  <p>Are you sure you want to save these changes?</p>
                  <button
                    className="btn btn-primary mr-2"
                    onClick={handleConfirmSaveBillingInfo}
                  >
                    Confirm
                  </button>
                  <button
                    className="btn btn-secondary"
                    onClick={handleCancelSaveBillingInfo}
                  >
                    Cancel
                  </button>
                </div>
              )}
            </div>
          )}

          {/* ---------------------------------------
              **Month Navigation**
          --------------------------------------- */}
          <div className="month-navigation mb-4 d-flex justify-content-between">
            <button
              className="btn btn-outline-primary"
              onClick={() => handleMonthChange(-1)}
            >
              Previous Month
            </button>
            <span className="current-month h4 my-auto">
              {currentMonth.format("MMMM YYYY")}
            </span>
            <button
              className="btn btn-outline-primary"
              onClick={() => handleMonthChange(1)}
            >
              Next Month
            </button>
          </div>

          {/* ---------------------------------------
              **Events and Actions for Current Month**
          --------------------------------------- */}
          {getEventsForCurrentMonth().length > 0 && (
            <>
              {/* ---------------------------------------
                  **Show Preview Button**
              --------------------------------------- */}
              <div className="d-flex justify-content-around mb-3">
                <button
                  className="btn btn-primary"
                  onClick={previewInvoice}
                  style={{ width: "45%" }}
                >
                  Show Preview of {selectedUser.first_name}{" "}
                  {selectedUser.last_name} Invoice for{" "}
                  {currentMonth.format("MMMM YYYY")}
                </button>
              </div>

              {/* ---------------------------------------
                  **Send Invoice Email Button**
              --------------------------------------- */}
              <div className="d-flex justify-content-around mb-3">
                <button
                  className="btn btn-secondary"
                  onClick={sendInvoiceToUser}
                  style={{ width: "45%" }}
                >
                  Send {selectedUser.first_name} {selectedUser.last_name}'s
                  Invoice for {currentMonth.format("MMMM YYYY")}
                </button>
              </div>

              {/* ---------------------------------------
                  **Events Accordion**
              --------------------------------------- */}
              <div className="accordion" id="invoiceAccordion">
                {getEventsForCurrentMonth().map((event, index) => (
                  <div key={event.event_id} className="mb-2">
                    <button
                      className="btn btn-light btn-block text-left"
                      type="button"
                      onClick={() => toggleInvoiceItemExpand(event.event_id)}
                    >
                      {event.title} -{" "}
                      {moment(event.start_time).format("MM/DD/YYYY")}
                    </button>
                    {expandedInvoiceItems[event.event_id] && (
                      <div className="card card-body">
                        <div className="form-group">
                          <label>Event Name</label>
                          <input
                            type="text"
                            value={event.title}
                            onChange={(e) =>
                              handleInvoiceItemChange(
                                event.event_id,
                                index,
                                "title",
                                e.target.value
                              )
                            }
                            className="form-control"
                          />
                        </div>
                        <div className="form-group">
                          <label>Billing Rate</label>
                          <input
                            type="number"
                            step="0.01"
                            value={event.billing_rate}
                            onChange={(e) =>
                              handleInvoiceItemChange(
                                event.event_id,
                                index,
                                "billing_rate",
                                e.target.value
                              )
                            }
                            className="form-control"
                          />
                        </div>
                        <div className="form-group">
                          <label>Billing Code</label>
                          <input
                            type="text"
                            value={event.billing_code}
                            onChange={(e) =>
                              handleInvoiceItemChange(
                                event.event_id,
                                index,
                                "billing_code",
                                e.target.value
                              )
                            }
                            className="form-control"
                          />
                        </div>
                        <button
                          className="btn btn-success"
                          onClick={() => saveChanges(event)}
                        >
                          Save Changes
                        </button>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default Invoicing;
