// src/components/Modal.js

import React, { useState } from "react";
import "./Modal.css";
import { useCart } from "../CartContext/CartContext";
import DefaultImage1 from "../../Assets/DefaultImage.webp";
import DefaultImage2 from "../../Assets/DefaultImage2.png";
import DefaultImage3 from "../../Assets/DefaultImage3.png";
import DefaultImage4 from "../../Assets/DefaultImage4.jpg";
import DefaultImage5 from "../../Assets/DefaultImage5.jpg";
import moment from "moment-timezone";

const Modal = ({ classInfo, onClose }) => {
  const { addToCart } = useCart();
  const [selectedDates, setSelectedDates] = useState([]);

  const defaultImages = [
    DefaultImage1,
    DefaultImage2,
    DefaultImage3,
    DefaultImage4,
    DefaultImage5
  ];

  if (!classInfo) return null;

  // Function to parse dates as local times
  const parseDateAsLocal = (timeString) => {
    // Parse the time string as UTC and convert to local time
    const localTime = moment.utc(timeString).local();
    return localTime;
  };

  const handleDateSelect = (date) => {
    console.log(`Selected Date: ${date}`);
    const eventForDate = classInfo.datesToEvents[date];
    const maxCapacity =
      eventForDate.max_capacity !== undefined ? eventForDate.max_capacity : 8;
    const currentEnrollments =
      eventForDate.current_enrollments !== undefined
        ? eventForDate.current_enrollments
        : 0;
    const isFull = currentEnrollments >= maxCapacity;

    if (isFull) {
      // Do not allow selection if the event is full
      return;
    }

    if (selectedDates.includes(date)) {
      setSelectedDates(selectedDates.filter((d) => d !== date));
    } else {
      setSelectedDates([...selectedDates, date]);
    }
  };

  const handleAddToCart = () => {
    console.log("Selected Dates:", selectedDates);

    selectedDates.forEach((selectedDate) => {
      const eventForDate = classInfo.datesToEvents[selectedDate];
      console.log(`Event for Date ${selectedDate}:`, eventForDate);

      if (!eventForDate) {
        console.error("No event data found for date:", selectedDate);
        return;
      }

      // Use the eventForDate as the event object
      addToCart(eventForDate);
    });

    onClose();
  };

  const createGoogleCalendarUrl = () => {
    if (!selectedDates.length) return "#"; // Ensures there are selected dates

    const eventForDate = classInfo.datesToEvents[selectedDates[0]];
    if (!eventForDate) {
      console.error("No event data found for selected date.");
      return "#";
    }

    // Parse event times as local times and then convert to UTC for Google Calendar
    const startTimeUTC =
      parseDateAsLocal(eventForDate.start_time)
        .utc()
        .format("YYYYMMDDTHHmmss") + "Z";
    const endTimeUTC =
      parseDateAsLocal(eventForDate.end_time).utc().format("YYYYMMDDTHHmmss") +
      "Z";

    const details = encodeURIComponent(classInfo.description);
    const title = encodeURIComponent(classInfo.title);

    return `https://calendar.google.com/calendar/r/eventedit?text=${title}&dates=${startTimeUTC}/${endTimeUTC}&details=${details}`;
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="modal-close-x" onClick={onClose}>
          &times;
        </button>{" "}
        {/* Close icon button */}
        <img
          src={classInfo.image_url}
          alt={classInfo.title}
          className="modal-image"
          onError={(e) => {
            e.target.onerror = null;
            const index = classInfo.id % defaultImages.length;
            e.target.src = defaultImages[index];
          }}
        />
        <h3>{classInfo.title}</h3>
        <div>
          <h4>Available Dates:</h4>
          <div className="date-selection">
            {classInfo.dates
              ?.filter((date) => parseDateAsLocal(date).isAfter(moment()))
              .sort(
                (a, b) =>
                  parseDateAsLocal(a).toDate() - parseDateAsLocal(b).toDate()
              )
              .map((date) => {
                const eventForDate = classInfo.datesToEvents[date];
                const maxCapacity =
                  eventForDate.max_capacity !== undefined
                    ? eventForDate.max_capacity
                    : 8;
                const currentEnrollments =
                  eventForDate.current_enrollments !== undefined
                    ? eventForDate.current_enrollments
                    : 0;
                const isFull = currentEnrollments >= maxCapacity;

                // Determine participantColorClass
                let participantColorClass = "";
                if (
                  currentEnrollments >= 6 &&
                  currentEnrollments < maxCapacity
                ) {
                  participantColorClass = "warning"; // Yellow
                } else if (isFull) {
                  participantColorClass = "full"; // Red
                }

                // Format the date in user's local time
                const formattedDate = parseDateAsLocal(date).format(
                  "MMMM D, YYYY h:mm A"
                );

                return (
                  <div key={date} className="date-item">
                    <button
                      className={`date-button ${
                        selectedDates.includes(date) ? "selected" : ""
                      }`}
                      onClick={() => handleDateSelect(date)}
                      disabled={isFull}
                    >
                      {formattedDate}
                    </button>
                    <p className={`participant-count ${participantColorClass}`}>
                      Participants: {currentEnrollments} / {maxCapacity}
                    </p>
                    {isFull && (
                      <p className="no-space-message">
                        No More Space Available on this Date
                      </p>
                    )}
                  </div>
                );
              })}
          </div>
        </div>
        <p>Price: ${classInfo.price}</p>
        <p className="modal-detail">{classInfo.description}</p>
        {/* Add to Calendar button */}
        <a
          href={createGoogleCalendarUrl()}
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="add-to-calendar">Add to Calendar</button>
        </a>
        <button className="modal-close" onClick={onClose}>
          Close
        </button>
        <button
          className="add-to-cart"
          onClick={handleAddToCart}
          disabled={selectedDates.length === 0}
        >
          Add to Cart
        </button>
      </div>
    </div>
  );
};

export default Modal;
